<template>
  <v-sheet rounded="xl" class="text-start pa-6" :width="$vuetify.breakpoint.xs ? '' : '586px'" maxWidth="586px">
    <div class="mb-6" :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-body-1'">My Token Locker</div>
    <v-form ref="form">
      <v-text-field
        label="Token address"
        clearable
        append-icon="mdi-magnify"
        outlined
        rounded
        class="primary secondary"
        :rules="[$rules.contractAddress]"
        @input="tokenAddressChange"
        dense
        :loading="loading"
        v-model="filterInput"
      >
        <template v-slot:prepend-inner>
          <div class="d-flex align-center">
            <v-img max-width="20" max-height="20" contain :src="require(`@/assets/binance.svg`)" />
          </div>
        </template>
      </v-text-field>
    </v-form>

    <div v-if="lockedTokensInfo.length > 0 && !filterInput">
      <v-sheet color="primary secondary pa-6" rounded="lg" v-for="(item, index) in lockedTokensInfo" :key="index">
        <router-link from="/locker/selectLockToken" :to="'/locker/lockTokens/' + item.token">
          <v-row class="ma-0 mb-4">
            <v-col cols="12" sm="6" class="pa-0 mb-3">
              <div class="d-flex align-center mb-0">
                <v-img max-width="28" contain class="mr-2" :src="require(`@/assets/binance.svg`)" />
                <div :class="$vuetify.breakpoint.smAndUp ? 'text-medium' : 'text-body-2'">
                  {{ item.name }}/{{ item.symbol }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pa-0 mb-3">
              <div class="d-flex align-center" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : ''">
                <v-img max-width="24" contain class="mr-2" :src="require(`@/assets/locker.svg`)" />
                <div :class="$vuetify.breakpoint.smAndUp ? 'text-medium' : 'text-body-2'">
                  {{ item.lockerAmount }} Locker
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="grey--text text-caption">Balance</div>
          <div class="text-body-1">{{ item.balance }}</div>
        </router-link>
      </v-sheet>
    </div>
    <v-sheet
      color="primary secondary pa-4 mb-6 d-flex flex-column justify-center align-center"
      :height="$vuetify.breakpoint.smAndUp ? 297 : ''"
      rounded="lg"
      class="text-center py-16"
      v-else-if="lockedTokensInfo.length <= 0 && !filterInput"
    >
      <div class="font-weight-bold text-medium">You don't have any token locks</div>
      <div class="text-caption">Enter the token address you’d like to lock</div>
    </v-sheet>
    <v-sheet color="primary secondary pa-6 mb-6" rounded="lg" v-else-if="filteredTokenInfo !== null">
      <router-link from="/locker/selectLockToken" :to="'/locker/lockTokens/' + filteredTokenInfo.tokenAddress">
        <v-row class="ma-0 mb-6">
          <v-col cols="12" sm="6" class="pa-0 mb-3">
            <div class="d-flex align-center mb-0">
              <v-img max-width="28" contain class="mr-2" :src="require(`@/assets/binance.svg`)" />
              <div :class="$vuetify.breakpoint.smAndUp ? 'text-medium' : 'text-body-2'">
                {{ filteredTokenInfo.name }}/{{ filteredTokenInfo.symbol }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 mb-3">
            <div class="d-flex align-center" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : ''">
              <v-img max-width="24" contain class="mr-2" :src="require(`@/assets/locker.svg`)" />
              <div :class="$vuetify.breakpoint.smAndUp ? 'text-medium' : 'text-body-2'">
                {{ filteredTokenInfo.lockerAmount }} Lock
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="grey--text text-caption">Balance</div>
        <div class="text-body-1">{{ filteredTokenInfo.balance }}</div>
      </router-link>
    </v-sheet>
  </v-sheet>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      lockedTokensInfo: [],
      filteredTokenInfo: null,
      filterInput: '',
    }
  },
  methods: {
    async tokenAddressChange(value) {
      if (!this.$refs.form.validate() || !value) {
        this.filteredTokenInfo = null
        return value
      }
      try {
        this.loading = false
        this.filteredTokenInfo = this.lockHandler ? await this.lockHandler.getTokenInfoByAddress(value) : {}
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
      return value
    },
    async fetchData() {
      if (this.lockHandler) {
        try {
          this.$loading.active = true
          this.lockedTokensInfo = await this.lockHandler.getUserLockedTokensInfo()
        } catch (e) {
          // this.$alert.error(e.message)
          console.error(e)
        } finally {
          this.$loading.active = false
        }
      }
    },
  },
  computed: {
    ...mapState('auth', ['lockHandler', 'account', 'lockHandler']),
  },
  async created() {
    await this.fetchData()
  },
}
</script>

